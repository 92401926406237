import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { MaineSuiteApiService } from '../../../services/maineSuiteApi.service';

import { MaineSuiteResourceService } from '../../../services/mainSuiteResource.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class DoubleMonthStickersResource extends MaineSuiteResourceService {
    public ApiUrl: string = '/';
    public ServerWithApiUrl = environment.maineSuiteHostName + '/';

    url = 'doubleMonthStickers';

    constructor(
        private http: HttpClient,
        maineSuiteApiService: MaineSuiteApiService
    ) {
        super(maineSuiteApiService);
    }

}
