import { Component, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';

import { WizardStepComponent } from './wizardStep.component';

@Component({
  selector: 'my-wizard',
  templateUrl: './wizard.html',
  styleUrls: ['./wizard.scss'],
  encapsulation: ViewEncapsulation.None
})

export class WizardComponent { 

	steps: WizardStepComponent[] = [];

	@Output()finish: EventEmitter<any> = new EventEmitter();

	@Input()currentStep: number = 1;
	@Output()currentStepChange: EventEmitter<any> = new EventEmitter();


	constructor(){}

	ngOnInit(){}

	ngOnChanges(){
		this.updateStep();
	}

	changeStep(index) {

		this.currentStep = index;
		this.currentStepChange.emit(index);

		this.updateStep();
	}

	updateStep(){
		this.steps.forEach((step, index) => {

			if(index + 1 == this.currentStep){
				step.active = true;
			}else{
				step.active = false;
			}			
		});
	}

	addStep(step: WizardStepComponent) {
		if (this.steps.length === 0) {
			step.active = true;
		}
		this.steps.push(step);
	}

	emitFinish(e){
		this.finish.emit(e);
	}

}