import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { City, TimeZone, ApiService } from '@towncloud/thor-api';
import { environment } from '../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CurrentTownService {
	private currentTownChange = new BehaviorSubject<City>(null); 
	private instanceId: number;
	// static currentTownChange = new EventEmitter();
	static town: City;
	static timeZone: TimeZone;
	static slug: string;
	public timeZone: string;
	static requestQueue: Array<any> = [];
	static processingRequest = false;

	public ApiUrl = '/';
	public ServerWithApiUrl = environment.thorHostName + '/';

	public tzIdentifiers = new Map ([
		["Hawaii-Aleutian Standard Time (HST)", "Pacific/Honolulu"], 
		["Alaska Standard Time (AKST)", "America/Juneau"], 
		["Pacific Standard Time (PST)", "America/Los_Angeles"], 
		["Mountain Standard Time (MST)", "America/Denver"], 
		["Central Standard Time (CST)", "America/Chicago"], 
		["Eastern Standard Time (EST)", "America/New_York"], 
		["Atlantic Standard Time (AST)", "America/Puerto_Rico"], 
		]);

	constructor (
		private http: HttpClient, 
	)	{ this.instanceId = Date.now(); // Assign a unique ID based on the current timestamp
		// console.log('CurrentTownService constructor');
		// console.log('Instance ID:', this.getInstanceId());
	}

	async setTown(town) {
		// console.log(town);
		if (CurrentTownService.slug != town) {
			ApiService.setCity(town);

			CurrentTownService.slug = town;
			// this.get().then((town) => {
			await this.doIt().then((town) => {
				// console.log(town);	
				this.emitChangeEvent(town);
			});
		}
	}

	get(): Promise<any> {
		return Promise.resolve(CurrentTownService.town);
	}

	getTown() {
		return CurrentTownService.slug;
	}

	getTimeZone() {
		// console.log(this.tzIdentifiers);
		// console.log(CurrentTownService.town);
		// console.log(this.timeZone);
		// console.log(this.tzIdentifiers.get(this.timeZone));
		const tzId = this.tzIdentifiers.get(this.timeZone);
		return tzId;
	}
	
	getInstanceId(): number {
    return this.instanceId;
  }

	emitChangeEvent(town) {
		// CurrentTownService.currentTownChange.emit(town);
		this.currentTownChange.next(town);

	}

	getChangeEmitter() {
		// return CurrentTownService.currentTownChange;
		return this.currentTownChange.asObservable();

	}

	moveToNextRequest() {
		CurrentTownService.requestQueue.shift();

		this.loopRequests();
	}

	loopRequests() {
		if (CurrentTownService.requestQueue.length > 0) {
			CurrentTownService.processingRequest = true;

			this.executeGet(CurrentTownService.requestQueue[0]);
		} else {
			CurrentTownService.processingRequest = false;
		}
	}

	async doIt(): Promise<any> {
		const promise = new Promise((resolve, reject) => {
			CurrentTownService.requestQueue.push({
				type: 'get',
				resolve: resolve,
				reject: reject,
			});
		});

		if (CurrentTownService.processingRequest == false) {
			this.loopRequests();
		}

		return promise;
	}

	async executeGet(request) {
		const accessToken = JSON.parse(localStorage.getItem('accessToken'));

		// if (accessToken == undefined) {
		// 	request.resolve(undefined);
		// 	this.moveToNextRequest();
		if (typeof CurrentTownService.town !== 'undefined') {
			request.resolve(CurrentTownService.town);
			this.moveToNextRequest();
		} else {
			if (!CurrentTownService.slug) {
				request.resolve(undefined);
				this.moveToNextRequest();
			} else {
				let headers = new HttpHeaders();

				if (accessToken) {
					headers = headers.set('X-Token', accessToken.token);
				}
				const options = {
					headers: headers,
				};

				return this.http
					.get(
						environment.thorHostName +
							'/cities?expand=timeZone,address.state&filter=(slug:eq:' +
							CurrentTownService.slug +
							')',
						options
					)
					.toPromise()
					.then((res: any) => {
						const data = res;

						if (data.page.totalCount > 0) {
							CurrentTownService.town = data.items[0];
							this.timeZone = CurrentTownService.town.timeZone.code; 
							// console.log(this.timeZone);
						} else {
							CurrentTownService.town = undefined;
						}

						request.resolve(CurrentTownService.town);
						this.moveToNextRequest();
					});
			}
		}
	}
}
