import { Injectable } from '@angular/core';
import { MaineSuiteApiService } from './maineSuiteApi.service';

import { ResourceParams } from '@towncloud/thor-api';

export class MaineSuiteResourceService {

	url: string;

	constructor(
		private maineSuiteApiService: MaineSuiteApiService
	){}

	async post(value){
		const data = {
			url: this.url,
			data: value
		}

		return await this.maineSuiteApiService.post(data);
	}

	create(value){
		return this.post(value)
	}

	async get(params = new ResourceParams){
		const data = {
			url: this.url,
			data: params
		}
		let results: any = await this.maineSuiteApiService.get(data);
		return results;
	}

	async select(id){
		var params = new ResourceParams(
			{ 
				filters: [
					[
						{
							property: '_id',
							operator: 'eq',
							value: id
						}
					]
				] 
			}
		);

		const data = {
			url: this.url,
			data: params
		}
		const response:any = await this.maineSuiteApiService.get(data);
		return response?.items[0]
	}

	async put(value: any = {}){
		let url = this.url;

		if(value.urlModifiers){
			if(value.urlModifiers.id){
				console.log("HAS ID")
				url += '/' + value.urlModifiers.id;
			}

			delete value.urlModifiers;
		}

		const data = {
			url,
			data: value
		}

		return await this.maineSuiteApiService.update(data)
	}

	update(value = {}){
		return this.put(value);
	}

	async delete(value: any = {}){
		let url = this.url;
		if(value.urlModifiers){
			if(value.urlModifiers.id){
				url += '/' + value.urlModifiers.id;
			}
		}

		const data = {
			url,
			data: value
		}
		return await this.maineSuiteApiService.delete(this.url)
	}
}
