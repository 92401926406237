
export { CurrentUserResource } from './currentUser.resource';

// Hammer API resources;
export { GridSettingsResource } from './gridSettings.resource';
export { CitySettingsResource } from './citySettings.resource';
export { CityLogoResource } from './cityLogo.resource';
export { DownloadResource } from './download.resource';
export { PasswordResource } from './password.resource';
export { MenuResource } from './menu.resource';
export { $UsersResource } from './users.resource';
export { $RolesResource } from './roles.resource';
export { LicenseRenewalLettersResource } from './licenseRenewalLetters.resource';
export { InfoRequestResource } from './infoRequest.resource';


// Main Suite resources;
export { ResidenceCodesResource } from './maineSuite/motorVehicles/residenceCodes.resource';
export { StateCodesResource } from './maineSuite/motorVehicles/stateCodes.resource';
export { CountyCodesResource } from './maineSuite/motorVehicles/countyCodes.resource';
export { MakeCodesResource } from './maineSuite/motorVehicles/makeCodes.resource';
export { OperatorCodesResource } from './maineSuite/motorVehicles/operatorCodes.resource';
export { ColorCodesResource } from './maineSuite/motorVehicles/colorCodes.resource';
export { StyleCodesResource } from './maineSuite/motorVehicles/styleCodes.resource';
export { ReasonCodesResource } from './maineSuite/motorVehicles/reasonCodes.resource';
export { ClassCodesResource } from './maineSuite/motorVehicles/classCodes.resource';
export { PrintPrioritiesResource } from './maineSuite/motorVehicles/printPriorities.resource';
export { FeesResource } from './maineSuite/motorVehicles/fees.resource';
export { DefaultValuesResource } from './maineSuite/motorVehicles/defaultValues.resource';
export { GrossVehicleWeightFeesResource } from './maineSuite/motorVehicles/grossVehicleWeightFees.resource';
export { VehiclesResource } from './maineSuite/motorVehicles/vehicles.resource';
export { RegistrationsResource } from './maineSuite/motorVehicles/registrations.resource';
export { ReportingPeriodsResource } from './maineSuite/motorVehicles/reportingPeriods.resource';

export { SingleMonthStickersResource } from './maineSuite/motorVehicles/singleMonthStickers.resource';
export { SingleYearStickersResource } from './maineSuite/motorVehicles/singleYearStickers.resource';
export { DoubleMonthStickersResource } from './maineSuite/motorVehicles/doubleMonthStickers.resource';
export { DoubleYearStickersResource } from './maineSuite/motorVehicles/doubleYearStickers.resource';
export { CombinationStickersResource } from './maineSuite/motorVehicles/combinationStickers.resource';
export { DecalsResource } from './maineSuite/motorVehicles/decals.resource';
export { BoostersResource } from './maineSuite/motorVehicles/boosters.resource';
export { Mvr3Resource } from './maineSuite/motorVehicles/mvr3.resource';
export { Mvr10Resource } from './maineSuite/motorVehicles/mvr10.resource';
export { PlatesResource } from './maineSuite/motorVehicles/plates.resource';
