import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../environments/environment';
@Injectable({
  providedIn: 'root', // Makes the service available application-wide
})
export class MaineSuiteApiService {
	private baseUrl = environment.maineSuiteHostName + '/';

	constructor(
		private http: HttpClient,
	) {
	}

	async get(request) {
    const params = request.data
    let queryParams = this.buildQueryString(params);

    const results: any = await this.http.get(this.baseUrl + request.url + queryParams).toPromise();

    if (params['$all']) {
      params.limit = results.page.totalCount;
      queryParams = this.buildQueryString(params);
      return await this.http.get(this.baseUrl + request.url + queryParams).toPromise();
    }

    return results;
	}

	async post(request) {
    return await this.http.post(this.baseUrl + request.url, request.data).toPromise();
	}

	async put(request) {
        return await this.http.put(this.baseUrl + request.url, request.data).toPromise();
	}

	async delete(request) {
    return await this.http.delete(this.baseUrl + request.url).toPromise();
	}

	create(data): Promise<any> {
		return this.post(data);
	}

	update(data): Promise<any> {
		return this.put(data);
	}

	buildQueryString(params) {
    let queryParams = '?offset=' + params.offset + '&limit=' + params.limit;
    if (params.sort) {
        if (params.sort.length > 0) {
            let sortString = '';
            params.sort.forEach(value => {
                if (sortString.length > 0) {
                    sortString += ',';
                }
                sortString += value.field + '|' + value.order;
            });
            queryParams += '&sortby=' + sortString;
        }
    }
    if (params.filters) {
        let filterString = '';
        params.filters.forEach(filter => {
            let fieldString = '';
            filter.forEach(field => {
                if (fieldString.length > 0) {
                    fieldString += ',';
                }
                fieldString += field.property + '|' + field.operator + '|' + field.value;
            });
            if (fieldString.length > 0) {
                fieldString = '(' + fieldString + ')';
            }
            if (filterString.length > 0) {
                filterString += ',';
            }
            filterString += fieldString;
        });
        queryParams += '&filter=' + encodeURIComponent(filterString);
    }
    if (params.expand) {
        queryParams += '&expand=' + params.expand;
    }
    return queryParams;
  }
}