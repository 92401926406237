import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CurrentTownService } from './currentTown.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private currentTownService: CurrentTownService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = JSON.parse(localStorage.getItem('accessToken'));
    const token = accessToken?.token;
    const currentEntity = JSON.parse(sessionStorage.getItem('entity'));
    // Convert the async `get` method to an Observable using `from`
    return from(this.currentTownService.get()).pipe(
      switchMap((town) => {
        // Clone the request with the required headers
        const headers = {}
        if(token) {
          // headers['Authorization'] = `Bearer ${token}`;
          headers['X-Token'] = token;
        }
        if(town) {
          headers['X-City'] = town.slug;
        }
        if(currentEntity) {
          headers['X-Entity'] = currentEntity.id.toString();
        }
        // console.log("HEADERS", headers);
        const clonedRequest = req.clone({setHeaders: headers });
        // console.log('clonedRequest', clonedRequest);
        // Pass the cloned request to the next handler
        return next.handle(clonedRequest);
      })
    );
  }
}
