import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { PermissionsRouteGuard } from '../../services/permissionsRouteGuard.service';
import { MyProfileComponent } from '../../modules/admin/myProfile/myProfile.component';
import { HomeComponent } from '../../modules/home/home.component';
import { NoFoundComponent } from './components/404/404.component';
import { NotAuthorizedComponent } from './components/notAuthorized/notAuthorized.component';
import { LoginComponent } from './components/login/login.component';
import { TcBodyComponent } from './components/body/tc-body.component';
import { AllMyOutputsComponent } from './components/allMyOutputs/allMyOutputs.component';

const appRoutes: Routes = [
	{ path: '', redirectTo: 'login', pathMatch: 'full'},
	{
	path: 'login',
	component: LoginComponent,
	},
	{
		path: '',
		component: TcBodyComponent,
		children: [
			{
				path: 'home',
				component: HomeComponent,
				// canActivate: [PermissionsRouteGuard],
				data: {
					name: 'home',
					breadCrumbs: [{ name: 'Home', path: '' }],
				},
			},
			{
				path: 'myProfile',
				component: MyProfileComponent,
				data: {
					name: 'myProfile',
					breadCrumbs: [
						{ name: 'Home', path: 'home' },
						{ name: 'My Profile', path: 'myProfile' },
					],
				},
			},
			{
				path: 'allMyOutputs',
				component: AllMyOutputsComponent,
				data: {
					name: 'allMyOutputs',
					breadCrumbs: [
						{ name: 'Home', path: 'home' },
						{ name: 'All My Outputs', path: 'allMyOutputs' },
					],
				},
			},
			{
				path: 'Admin',
				loadChildren: '../admin/admin.module#AdminModule',
			},
		]
	},

	{
		path: 'notAuthorized',
		component: NotAuthorizedComponent,
	},
	{
		path: ':town/notAuthorized',
		component: NotAuthorizedComponent,
	},
	{
		path: ':town',
		children: [

			{ path: '', redirectTo: 'login', pathMatch: 'full'},
			{
				path: 'login',
				component: LoginComponent,
			},
			{
				path: '',
				component: TcBodyComponent,
				children: [
					{
						path: 'home',
						component: HomeComponent,
						// canActivate: [PermissionsRouteGuard],
						data: {
							name: 'home',
							breadCrumbs: [{ name: 'Home', path: '' }],
						},
					},
					{
						path: 'myProfile',
						component: MyProfileComponent,
						data: {
							name: 'myProfile',
							breadCrumbs: [
								{ name: 'Home', path: ':town/home' },
								{ name: 'My Profile', path: ':town/myProfile' },
							],
						},
					},
					{
						path: 'allMyOutputs',
						component: AllMyOutputsComponent,
						data: {
							name: 'allMyOutputs',
							breadCrumbs: [
								{ name: 'Home', path: ':town/home' },
								{ name: 'All My Outputs', path: ':town/allMyOutputs' },
							],
						},
					},
					{
						path: 'Admin',
						loadChildren: '../admin/admin.module#AdminModule',
					},
					{
						path: 'Revenue',
						loadChildren: '../billing/billing.module#BillingModule',
					},
					{
						path: 'Development',
						loadChildren: '../development/development.module#DevelopmentModule',
					},
					{
						path: 'Finance',
						loadChildren: '../financials/financials.module#FinancialsModule',
					}
				]
			}
		],
	},
	// {
	// 	path: ':town/notAuthorized',
	// 	component: NotAuthorizedComponent,
	// },
	{
		path: '**',
		component: NoFoundComponent,
	},
];

export const appRoutingProviders: any[] = [];

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(appRoutes);
// export const routing: ModuleWithProviders<any> = RouterModule.forRoot(appRoutes, {enableTracing: true});
